<template>
    <div class="advantages">
        <div class="advantages__wrapper">
            <h1 class="advantages__title">
                Деньги тут — это удобно
            </h1>
            <p class="advantages__desc">
                Моментальный подбор подходящих займов: просто укажите, какая сумма вам необходима. Мы подберем предложения с моментальным переводом денег на карту.
            </p>
            <ul class="advantages__list">
                <li class="advantages__visa">
                    <img src="./assets/visa.png"/>
                </li>
                <li class="advantages__master">
                    <img src="./assets/mastercard.png"/>
                </li>
                <li class="advantages__maestro">
                    <img src="./assets/maestro.png"/>
                </li>
                <li class="advantages__qiwi">
                    <img src="./assets/qiwi.png"/>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import './advantages.scss'

export default {
    name: 'Advantages'
}
</script>